<template>
  <div class="col-12 card card-body">
    <div class="col-12 table-responsive">
        <div class="col-12 text-center g" v-if="loading">
            <img src="/files/loading.svg" alt="">
        </div>
        <div class="g"><input type="text" class="form-control form-control-lg" @change="getArchive()" v-model="q" placeholder="ابحث هنا..."></div>
        <div class="g"><select class="form-control form-control-lg" @change="getArchive()" v-model="status">
            <option :value="null">الكل</option>
            <option value="0">لم تتم</option>
            <option value="1">ناجحة</option>
            <option value="2">فاشلة</option>
            </select></div>
        <table class="table table-sm table-hover table-bordered">
            <thead>
                <th>
                    ID
                </th>
                <th>
                    ID مدرستي
                </th>
                <th>
                    الاسم
                </th>
                <th>
                    الجوال
                </th>
                <th>
                    التاريخ
                </th>
                <th>
                    المبلغ
                </th>
                <th>
                    الحالة
                </th>
                <th>
                    تاريخ الانتهاء
                </th>
                <th>
                    كود الترويج
                </th>
                <th>
                    خيارات
                </th>
            </thead>
            <tbody>
                <tr v-for="message in messages" :key="message._id">
                    <td>
                        {{ message.order_id }}
                    </td>
                    <td>
                        <a href="javascript:;" @click="$router.push('/school/' + message.school_id)">{{ message.school_id }}</a> 
                        <i class='fa fa-edit' @click='changes(message.order_id, message.school_id)'></i>
                    </td>
                    <td>
                        <span v-if="message.m" class="text-danger">[مشرف]</span>
                        {{ message.name }}
                    </td>
                    <td>
                        {{ message.phone }}
                    </td>
                    <td>
                        {{ message.date }}
                    </td>
                    <td>
                        {{ message.amount }}
                    </td>
                    <td>
                        <span v-if="message.status == 0 || !message.status" class="btn btn-sm btn-warning btn-block">
                            <i class="fa fa-clock-o"></i>
                            لم تتم
                        </span>
                        <span v-if="message.status == 1" class="btn btn-sm btn-success btn-block">
                            <i class="fa fa-check"></i>
                            تم بنجاح
                        </span>
                        <span v-if="message.status == 2" class="btn btn-sm btn-danger btn-block">
                            <i class="fa fa-times"></i>
                            فاشلة
                        </span>
                    </td>
                    <td>
                        {{ message.exdate }}
                    </td>
                    <td>
                        {{ message.code }}
                    </td>
                    <td>
                        <button class="btn btn-sm btn-secondary btn-block" v-if="!message.edit" @click="message.edit=true">
                            <i class="fa fa-edit"></i>
                            تغيير الحالة
                        </button>
                        <select v-model="message.status" v-if="message.edit" style="border:none; width: 100%" @change="changeStatus(message.order_id, message.status)">
                            <option :value="0">لم تتم</option>
                            <option :value="1">تم بنجاح</option>
                            <option :value="2">فاشلة</option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <button class="btn btn-danger" @click="page--; getArchive()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
        <button class="btn btn-primary" @click="page++; getArchive()" v-if="messages.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            messages: [],
            page: 0,
            loading: false,
            q: window.location.hash ? window.location.hash.replace("#", "") : null,
            status: null
        }
    },
    created(){
        this.getArchive();
    },
    methods: {
        getArchive(){
            var g = this;
            this.loading = true;
            $.post(api + '/admin/orders', {
                jwt: localStorage.getItem("jwt"),
                page: this.page,
                search: this.q,
                status: this.status
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.messages = JSON.parse(JSON.stringify(response.response)).map(function(a){
                        a.edit = false
                        return a
                    })
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        },
        changeStatus(id, status){
            if(confirm("متاكد من تغيير الحالة؟")){
                var g = this;
            this.loading = true;
            $.post(api + '/admin/orders/change-status', {
                jwt: localStorage.getItem("jwt"),
                order_id: id,
                status: status
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.getArchive()
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
            }
        },
        changes(id, s){
            var f = prompt("school_id", s)
            var g = this;
            this.loading = true;
            $.post(api + '/admin/orders/school_id_to_order', {
                jwt: localStorage.getItem("jwt"),
                order_id: id,
                school_id: f
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.getArchive()
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        }
    }
}
</script>

<style>
th , td{
    white-space: nowrap !important;
}
</style>